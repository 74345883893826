import React from "react";
import { Link } from "gatsby";
import { startCase, compact } from "lodash";
import * as styles from "./blogTagCloud.module.css";
import { useStaticQuery, graphql } from "gatsby";
import { TagCloud } from "react-tagcloud";

const BlogTagCloud = () => {
  const data = useStaticQuery(graphql`
    query BlogTagCloudQuery {
      allMdx(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            frontmatter {
              date
              slug
              title
              tags
            }
          }
        }
      }
    }
  `);

  const allTagsByCount = {};
  const posts = data?.allMdx?.edges || [];

  posts?.forEach((post) => {
    const postTags = post?.node?.frontmatter?.tags || [];

    postTags.forEach((tag) => {
      if (allTagsByCount[tag]) {
        allTagsByCount[tag] += 1;
      } else {
        allTagsByCount[tag] = 1;
      }
    });
  });

  // const tagCountThreshold = Math.max(...Object.values(allTagsByCount)) / 10;
  const tagCountThreshold = 5;
  const tagCloudData = compact(
    Object.entries(allTagsByCount).map(([key, value]) => {
      if (value < tagCountThreshold) {
        return undefined;
      }

      return {
        value: key,
        count: value,
      };
    })
  );

  const customRenderer = (tag, size, color) => {
    return (
      <span
        key={tag.value}
        style={{
          fontSize: `${size * 1.5}px`,
          padding: "6px",
          display: "inline-block",
        }}
        className={`tag-${size}`}
      >
        <Link to={`/posts/tag/${tag.value}/`} title={tag.value}>
          <span key={tag.value}>{startCase(tag.value)}</span>
        </Link>
      </span>
    );
  };

  return (
    <div className={styles.gridCenter}>
      <h2
        className={`mt-8 flex items-center justify-left text-purple-800 ${styles.heading}`}
      >
        Popular Tags
      </h2>
      <TagCloud
        className={styles.tagCloudWrapper}
        minSize={12}
        maxSize={30}
        tags={tagCloudData}
        renderer={customRenderer}
      />
    </div>
  );
};

export default BlogTagCloud;
